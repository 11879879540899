import mixitup from 'mixitup';
import axios from 'axios'

export default (() => {
  let containerProducts = document.querySelector('.projects');

  let mixer;
  if(containerProducts != undefined) {
    mixer = mixitup(containerProducts);
  };

  let count = 0;
  function btnLoad(e) {
    if (e.target.classList.contains('btn-load')) {
      e.preventDefault();

      let _self = e.target,
          loadWrapperId = _self.getAttribute('data-load'),
          loadWrapperElem = document.querySelector('[data-load-parent="'+loadWrapperId+'"]'),
          dataOffset = _self.getAttribute('data-offset'),
          dataUrl = _self.getAttribute('data-url');

          count = +count + +dataOffset;
          let url = dataUrl.replace('\{n}', count);

      axios.get(url)
      .then((response) => {
        loadWrapperElem.innerHTML +=  baseTemplate(response.data)
        if(mixer != undefined) {
          mixer.forceRefresh();
        };
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        // always executed
      });
    }
  }

  function baseTemplate(items) {
    let listHtml = '';
    if(items.length) {
        let templateHtml = document.getElementById("pagination-template").innerHTML;
        items.forEach(element => {
          listHtml += templateHtml.replace(/\${img}/g, element.avatar.url)
                                  .replace(/\${title}/g, element.title)
                                  .replace(/\${desc}/g, element.description)
                                  .replace(/\${city}/g, element.city.name)
                                  .replace(/\${url}/g, element.url)
                                  .replace(/\${date}/g, element.start_date)
                                  .replace(/\${line}/g, element.progress_percent)
                                  .replace(/\${time}/g, element.remained_time)
                                  .replace(/\${collect}/g, element.target_balance)
                                  .replace(/\${collected}/g, element.earned_balance)
                                  .replace(/\${category}/g, element.is_need_volunteer ? 'category-a' : 'category-b')
        });
    }
    return listHtml;
  }
  document.addEventListener("click", btnLoad, { passive: false });
  document.addEventListener("touchstart", btnLoad, { passive: false });



})();
