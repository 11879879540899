import sayHello from './lib/sayHello';
import './lib/carousel';
import './lib/inner-carousel';
import './lib/projects-carousel';
import './lib/choices';
import './lib/datepicker'
import './lib/mixitup'
import './lib/sidebar'
import './lib/share'
import YoutubePlayer from './lib/ytvideo'

sayHello();

// eslint-disable-next-line no-unused-vars
const yt = new YoutubePlayer();
