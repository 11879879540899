import datepicker from 'js-datepicker'

export default (() => {
  let calendar = document.querySelector('.calendar');
  if(calendar == undefined) return;

    const picker = datepicker('.calendar', {
      showAllDates: true,
      startDay: 1,
      customMonths: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      customDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      // disableYearOverlay: true,
      overlayButton: 'Выбрать',
      overlayPlaceholder: 'год',
      formatter: (input, date, instance) => {
        const value = date.toLocaleDateString()
        input.value = value
      },
    })
})();
