if (!Element.prototype.closest) {
    Element.prototype.closest = function(css) {
      var node = this;

      while (node) {
        if (node.matches(css)) return node;
        else node = node.parentElement;
      }
      return null;
    };
}

export default class YoutubePlayer {
    constructor(elem = "[data-ytvideo]") {
      this.elems = document.querySelectorAll(elem);

      this.init();
    }

    init() {
      if (!this.elems.length) return;
      this.elems.forEach(element => {
        let _self = element,
          iframe = _self.querySelector("iframe"),
          url = iframe.attributes.src.value,
          videoId = this.getYoutubeId(url),
          ytvideo__poster = `http://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`,
          ytvideo__posterElement = document.createElement("div");

        ytvideo__posterElement.style.backgroundImage = `url(${ytvideo__poster})`;
        ytvideo__posterElement.classList = "video-poster";

        _self.appendChild(ytvideo__posterElement);

        element.addEventListener("click", e => {
          e.preventDefault();
          if (e.target.matches("div")) {
            this.playVideo(element, iframe);
          }
        });
      });
    }

    getYoutubeId(url) {
      url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
    }

    playVideo(element, iframe) {
      if (element.classList.contains("is--played")) return;

      let url = iframe.attributes.src.value,
        normalizeUrl =
          url.indexOf("?") !== -1 ? url + "&autoplay=1" : url + "?autoplay=1";

      element.classList.add("is--played");

      iframe.attributes.src.value = normalizeUrl;
    }
}
