import { Swiper, Navigation, EffectFade } from 'swiper/js/swiper.esm.js';

export default (() => {
  Swiper.use([Navigation, EffectFade]);

  let carouselContainer = document.querySelector('.inner-carousel');

  if(carouselContainer == undefined) return;

  let items = carouselContainer.querySelectorAll('.swiper-slide').length;
  let carousel = new Swiper(carouselContainer.querySelector('.swiper-container'), {
    loop: items > 2,
    watchOverflow: items <= 2,
    slidesPerView: 1,
    spaceBetween: 24,
    navigation: {
      nextEl: carouselContainer.querySelector('.btn-carousel-next'),
      prevEl: carouselContainer.querySelector('.btn-carousel-prev'),
    },
    breakpoints: {
      760: {
        slidesPerView: 2,
      }
    }
  });

  carousel.init();
})();
