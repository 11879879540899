import JSShare from "js-share";

export default (() => {
  let shareItems = document.querySelectorAll('.social_share');
  if(shareItems == undefined) return;

  for (var i = 0; i < shareItems.length; i += 1) {
    shareItems[i].addEventListener('click', function share(e) {
      return JSShare.go(this);
    });
  }
})();
