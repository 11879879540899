export default (() => {
  function sidebarToggle (e) {
      let header = document.querySelector('.header');
      if(e.target.classList.contains('btn--burger')) {
          e.preventDefault();
          header.classList.toggle("sidebar-opened")
      }
  }
  document.addEventListener("click", sidebarToggle, { passive: false });
  document.addEventListener("touchstart", sidebarToggle, { passive: false });
})();
