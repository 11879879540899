import { Swiper, Navigation, EffectFade } from 'swiper/js/swiper.esm.js';

export default (() => {
  Swiper.use([Navigation, EffectFade]);

  let carouselContainer = document.querySelector('.footer-carousel');

  if(carouselContainer == undefined) return;
  let items = carouselContainer.querySelectorAll('.swiper-slide').length;
  let carousel = new Swiper(carouselContainer.querySelector('.swiper-container'), {
    loop: items > 2,
    // autoplay: {
    //   delay: 5000,
    // },
    slidesPerView: 1,
    spaceBetween: 24,
    navigation: {
      nextEl: carouselContainer.querySelector('.btn-carousel-next'),
      prevEl: carouselContainer.querySelector('.btn-carousel-prev'),
    },
    breakpoints: {
      500: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      960: {
        slidesPerView: 4,
      },
      1210: {
        slidesPerView: 5,
      }
    }
  });

  carousel.init();
})();
