import Choices from 'choices.js'
export default (() => {
  let carouselContainer = document.querySelectorAll('.form-select');
  if(carouselContainer == undefined) return;
  carouselContainer.forEach(element => {
    const choices = new Choices(element, {
      placeholder: true,
      searchEnabled: false,
      itemSelectText: '',
    })
  });
})();
